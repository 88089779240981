export default {
  rcodes: {
    about: {
      header: 'About the page',
      paragraph: "It's a simple showcase of Three.js possibilities.",
      tech_stack: 'Technological stack',
    },
    contact: {
      header: 'Contact',
    },
    navbar: {
      about: 'About',
      configurator: 'Car configurator',
      contact: 'Contact',
      main_page: 'Main page',
    },
  },
};
