import React from "react";
import { Html } from "drei";

const Loading = () => {
  return (
    <Html>
      <div id="loader"></div>
    </Html>
  );
};

export default Loading;
