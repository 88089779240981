import React, { Suspense, useMemo, useRef, useState } from "react";
import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useRender,
  useThree,
  useUpdate,
} from "react-three-fiber";
import * as THREE from "three";
import { animated, useSpring, config } from "react-spring-three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {
  BloomEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
  BlendFunction,
  KernelSize,
} from "postprocessing";

import Loading from "./Loading";

const ColorLight = ({ color, position }) => {
  return (
    <pointLight
      color={color}
      intensity={50}
      distance={450}
      decay={1.7}
      position={position}
    />
  );
};

const Lights = () => {
  return (
    <React.Fragment>
      <ambientLight color={"#000"} />
      <directionalLight color={"#fff"} position={[0, 0, 1]} />
      <ColorLight color={"#000"} position={[200, 300, 100]} />
      <ColorLight color={"#000"} position={[100, 300, 100]} />
      <ColorLight color={"#000"} position={[300, 300, 200]} />
    </React.Fragment>
  );
};

const Fog = () => {
  const { gl, scene } = useThree();

  scene.fog = new THREE.FogExp2(0x03544e, 0.001);
  gl.setClearColor(scene.fog.color);

  return null;
};

const Clouds = ({ smoke_url, ...props }) => {
  const texture = useLoader(THREE.TextureLoader, smoke_url);

  return new Array(50).fill().map((_, i) => {
    return <Cloud key={i} texture={texture} subpage={props.subpage} />;
  });
};

const Cloud = ({ texture, subpage }) => {
  const cloud = useRef();
  const xPosition = useRef(Math.random() * 800 - 400);
  const zRotation = useRef(Math.random() * 2 * Math.PI);
  const { scale } = useSpring({
    scale: subpage ? [1, 1, 1] : [0, 0, 0],
    config: subpage ? { mass: 1, tension: 280, friction: 720 } : config.default,
  });

  useFrame((state, delta) => {
    cloud.current.rotation.z += 0.001;
  });

  return (
    <animated.mesh
      ref={cloud}
      className={"cloud"}
      position={[xPosition.current, 0, -20]}
      rotation={[0, 0, zRotation.current]}
      scale={scale}
    >
      <planeBufferGeometry attach="geometry" args={[500, 500]} />
      <meshLambertMaterial
        attach="material"
        map={texture}
        transparent={true}
        opacity={0.35}
      />
    </animated.mesh>
  );
};

const SmokeCameraControls = () => {
  const { camera } = useThree();

  camera.fov = 60;
  camera.near = 1;
  camera.position.set(0, 0, 1);
  camera.rotation.set(1.16, -0.12, 0.27);

  return null;
};

const PostProcessing = () => {
  const { camera, gl, scene } = useThree();

  const bloomEffect = new BloomEffect({
    blendFunction: BlendFunction.COLOR_DODGE,
    kernelSize: KernelSize.SMALL,
    useLuminanceFilter: true,
    luminanceThreshold: 0.3,
    luminanceSmoothing: 0.75,
  });
  bloomEffect.blendMode.opacity.value = 1.5;

  let effectPass = new EffectPass(camera, bloomEffect);
  effectPass.renderToScreen = true;

  const composer = new EffectComposer(gl);
  composer.addPass(new RenderPass(scene, camera));
  composer.addPass(effectPass);

  return null;
};

const Smoke = ({ smoke_url, ...props }) => {
  const scene = useRef();
  const logoGroup = useRef();
  const { camera } = useThree();
  useFrame(
    ({ gl }) =>
      void ((gl.autoClear = false),
      gl.clearDepth(),
      gl.render(scene.current, camera)),
    11
  );

  return (
    <scene ref={scene}>
      <Suspense fallback={<Loading />}>
        <Clouds smoke_url={smoke_url} subpage={props.subpage} />
      </Suspense>
      <PostProcessing />
      <Lights />
    </scene>
  );
};

export default Smoke;
