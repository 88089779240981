import React, { Suspense, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";

import CarConfigurator from "./CarConfigurator";
import Notice from "./Notice";

import { COLORS } from "./colors";

const Swatches = ({ color, onColorChange }) => {
  const selectSwatch = (event) => {
    onColorChange(COLORS[parseInt(event.target.dataset.key)]);
  };

  const slider = useRef(null);
  const sliderItems = useRef(null);

  let difference;
  let posX1 = 0;
  let posX2 = 0;
  let posInitial;
  let threshold = 20;
  let posFinal;

  const buildColors = () => {
    return COLORS.map((color, i) => {
      let style = useMemo(() => {
        return color.texture
          ? { backgroundImage: `url(${color.texture})` }
          : { background: `#${color.color}` };
      });

      return (
        <div
          className={"tray__swatch"}
          data-key={i}
          key={i}
          onClick={(event) => selectSwatch(event)}
          style={style}
        />
      );
    });
  };

  const dragStart = (e) => {
    e = e || window.event;
    posInitial = sliderItems.current.offsetLeft;
    difference = sliderItems.current.offsetWidth - slider.current.offsetWidth;
    difference = difference * -1;

    if (e.type == "touchstart") {
      posX1 = e.touches[0].clientX;
    } else {
      posX1 = e.clientX;
      document.onmouseup = dragEnd;
      document.onmousemove = dragAction;
    }
  };

  const dragEnd = (e) => {
    posFinal = sliderItems.current.offsetLeft;
    if (posFinal - posInitial < -threshold) {
    } else if (posFinal - posInitial > threshold) {
    } else {
      sliderItems.current.style.left = posInitial + "px";
    }

    document.onmouseup = null;
    document.onmousemove = null;
  };

  const dragAction = (e) => {
    e = e || window.event;

    if (e.type == "touchmove") {
      posX2 = posX1 - e.touches[0].clientX;
      posX1 = e.touches[0].clientX;
    } else {
      posX2 = posX1 - e.clientX;
      posX1 = e.clientX;
    }

    if (
      sliderItems.current.offsetLeft - posX2 <= 0 &&
      sliderItems.current.offsetLeft - posX2 >= difference
    ) {
      sliderItems.current.style.left =
        sliderItems.current.offsetLeft - posX2 + "px";
    }
  };

  return (
    <div className="controls">
      <div id="js-tray" className="tray" ref={slider}>
        <div
          id="js-tray-slide"
          className="tray__slide"
          ref={sliderItems}
          onMouseDown={(event) => dragStart(event)}
          onTouchStart={dragStart}
          onTouchEnd={dragEnd}
          onTouchMove={dragAction}
        >
          {buildColors()}
        </div>
      </div>
    </div>
  );
};

const Parts = ({ activePart, onPartChange }) => {
  const parts = ["body", "wheels", "interior", "windows"];

  return (
    <div className="options">
      {parts.map((part, i) => {
        return (
          <div
            className={`option ${activePart == part ? "--is-active" : ""}`}
            key={i}
            onClick={() => onPartChange(part)}
          >
            <img src={`images/rcodes/${part}.svg`} alt={part} />
          </div>
        );
      })}
    </div>
  );
};

const CarConfiguratorControls = ({ subpage, delay }) => {
  const [activeColor, setActiveColor] = useState();
  const [activePart, setActivePart] = useState("body");

  if (subpage != "car_configurator") {
    return null;
  } else {
    return (
      <React.Fragment>
        <Canvas
          className={"canvas-container"}
          shadowMap
          style={{ position: "absolute" }}
        >
          <CarConfigurator activeColor={activeColor} activePart={activePart} />
        </Canvas>
        <Swatches onColorChange={setActiveColor} />
        <Parts activePart={activePart} onPartChange={setActivePart} />
      </React.Fragment>
    );
  }
};

export default CarConfiguratorControls;
