export default {
  rcodes: {
    about: {
      header: 'O stronie',
      paragraph: '...niewiele więcej można powiedzieć niż to, że jest placem zabaw z takimi technologiami jak React i Three.js, a zarazem wizytówką, na którą nie miałem lepszego pomysłu :)',
      tech_stack: 'Wykorzystane technologie',
    },
    contact: {
      header: 'Kontakt',
    },
    navbar: {
      about: 'O stronie',
      configurator: 'Konfigurator samochodowy',
      contact: 'Kontakt',
      main_page: 'Strona głowna',
    },
  },
};
