import React, { Suspense, useEffect, useRef } from "react";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";

import Logo from "./Logo";
import Smoke from "./Smoke";

const SceneManager = ({ diamond_url, smoke_url, ...props }) => {
  const camera = useRef();
  const {
    setDefaultCamera,
    size: { width, height },
  } = useThree();

  // useEffect(() => void setDefaultCamera(camera.current), []);
  useFrame(() => camera.current.updateMatrixWorld());

  return (
    <React.Fragment>
      <perspectiveCamera
        ref={camera}
        onUpdate={(self) => self.updateProjectionMatrix()}
      />
      <Logo diamond_url={diamond_url} />
      <Smoke smoke_url={smoke_url} subpage={props.subpage} />
    </React.Fragment>
  );
};

export default SceneManager;
