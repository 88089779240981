import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useThree,
  useUpdate,
} from "react-three-fiber";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { COLORS } from "./colors";

import Loading from "./Loading";

extend({ OrbitControls });

const CameraControls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();

  useFrame((state) => {
    controls.current.update();
  });

  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      enableZoom
      maxPolarAngle={Math.PI / 2}
      minPolarAngle={Math.PI / 3}
    />
  );
};

const Lights = () => {
  const mapSize = useMemo(() => new THREE.Vector2(1024, 1024), []);

  return (
    <React.Fragment>
      <hemisphereLight
        color={"#ffffff"}
        groundColor={"#ffffff"}
        intensity={0.5}
        position={[0, 50, 0]}
      />
      <directionalLight
        color={"#ffffff"}
        intensity={0.5}
        position={[-8, 12, 8]}
        castShadow
        mapSize={mapSize}
      />
      <directionalLight
        color={"#ffffff"}
        intensity={0.5}
        position={[0, 100, 100]}
        castShadow
      />
      <ambientLight color={"#404040"} intensity={0.5} />
      <pointLight color={"#c4c4c4"} intensity={0.5} position={[0, 300, 500]} />
      <pointLight color={"#c4c4c4"} intensity={0.5} position={[0, 100, 0]} />
      <pointLight color={"#c4c4c4"} intensity={0.5} position={[0, 100, -500]} />
    </React.Fragment>
  );
};

const Floor = () => {
  return (
    <mesh position={[0, -1, 0]} receiveShadow rotation={[-0.5 * Math.PI, 0, 0]}>
      <planeGeometry attach="geometry" args={[5000, 5000, 1, 1]} />
      <meshPhongMaterial attach="material" color={"#eeeeee"} shininess={0} />
    </mesh>
  );
};

const aventadorParts = (activePart) => {
  switch (activePart) {
    case "body":
      return ["object028_Material005_0"];
    case "interior":
      return [
        "object025_M_1_0",
        "object006_M_1_0",
        "object016_interior_lod0__s1_0",
      ];
    case "windows":
      return ["object018_Material004_0"];
    case "wheels":
      return ["wheel005_Material010_0", "wheel005_Material009_0"];
  }
};

const Aventador = ({ activeColor, activePart }) => {
  const { scene, nodes } = useLoader(
    GLTFLoader,
    "3d_models/rcodes/aventador_j.gltf"
  );
  const model = useRef();

  const { raycaster } = useThree();

  useEffect(() => {
    if (activeColor) {
      let new_mtl;
      let shininess = activeColor.shininess ? activeColor.shininess : 10;

      if (activeColor.texture) {
        let txt = new THREE.TextureLoader().load(activeColor.texture);

        txt.repeat.set(
          activeColor.size[0],
          activeColor.size[1],
          activeColor.size[2]
        );
        txt.wrapS = THREE.RepeatWrapping;
        txt.wrapT = THREE.RepeatWrapping;

        new_mtl = new THREE.MeshPhongMaterial({
          map: txt,
          shininess: shininess,
        });
      } else {
        new_mtl = new THREE.MeshPhongMaterial({
          color: `#${activeColor.color}`,
          shininess: shininess,
        });
      }
      let i = 5;
      scene.traverse((node) => {
        if (
          node.type == "Mesh" &&
          aventadorParts(activePart).indexOf(node.name) != -1
        ) {
          node.material.color = new THREE.Color(
            parseInt(`0x${activeColor.color}`)
          );
        }
      });
    }
  }, [activeColor]);

  return (
    <primitive
      ref={model}
      visible
      object={scene}
      scale={[0.006, 0.006, 0.006]}
    />
  );
};

const Chair = ({ activeColor, activePart }) => {
  const { scene, nodes } = useLoader(
    GLTFLoader,
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/chair.glb"
  );
  const chair = useRef();

  const defaultMaterial = useMemo(
    () => new THREE.MeshPhongMaterial({ color: 0xf1f1f1, shininess: 10 })
  );

  useMemo(
    () =>
      scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;
          node.material = defaultMaterial;
        }
      }),
    [scene]
  );

  useEffect(() => {
    if (activeColor) {
      let new_mtl;
      let shininess = activeColor.shininess ? activeColor.shininess : 10;

      if (activeColor.texture) {
        let txt = new THREE.TextureLoader().load(activeColor.texture);

        txt.repeat.set(
          activeColor.size[0],
          activeColor.size[1],
          activeColor.size[2]
        );
        txt.wrapS = THREE.RepeatWrapping;
        txt.wrapT = THREE.RepeatWrapping;

        new_mtl = new THREE.MeshPhongMaterial({
          map: txt,
          shininess: shininess,
        });
      } else {
        new_mtl = new THREE.MeshPhongMaterial({
          color: `#${activeColor.color}`,
          shininess: shininess,
        });
      }

      scene.traverse((node) => {
        if (node.isMesh && activePart == node.name) {
          node.material = new_mtl;
        }
      });
    }
  }, [activeColor]);

  return (
    <primitive
      ref={chair}
      visible
      object={scene}
      position={[0, -1, 0]}
      rotation={[0, Math.PI, 0]}
      scale={[2, 2, 2]}
    />
  );
};

const CarConfigurator = ({ activeColor, activePart }) => {
  return (
    <React.Fragment>
      <CameraControls />
      <Lights />
      <Suspense fallback={<Loading />}>
        <Aventador activeColor={activeColor} activePart={activePart} />
      </Suspense>
    </React.Fragment>
  );
};

export default CarConfigurator;
