import React from 'react';
import { animated, useTransition } from 'react-spring';
import I18n from 'i18n-js';

function About({ subpage, delay }) {
  const transitions = useTransition(subpage === 'about', null, {
    initial: { transform: 'translate3d(0%, -100%, 0)' },
    from: { transform: 'translate3d(0%,-100%, 0)' },
    enter: (item) => async (next, cancel) => {
      await new Promise((resolve) => setTimeout(resolve, delay));
      await next({ transform: 'translate3d(0%, 0%, 0)' });
    },
    leave: { transform: 'translate3d(0%, 200%, 0)' },
    delay: 10000,
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props }) => item && (
        <animated.div className="content" key={key} style={props}>
          <div className="inner">
            <h2>{ I18n.t('rcodes.about.header') }</h2>
            <div className="title-divider" />
            <p>{ I18n.t('rcodes.about.paragraph') }</p>
            <div className="skills-con">
              <div className="full-divider" />
              <div className="container-sub skill-list">
                <div className="row">
                  <h3>{ I18n.t('rcodes.about.tech_stack') }</h3>
                  <div className="col-4 margin-top10">
                    <ul>
                      <li>Ruby on Rails</li>
                      <li>React.js</li>
                      <li>react-three-fiber</li>
                      <li>react-spring</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </animated.div>
        ),
      )}
    </div>
  );
}

export default About;
