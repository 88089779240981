import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useThree,
  useUpdate,
} from "react-three-fiber";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ObjectControls } from "threejs-object-controls";

import Loading from "./Loading";

extend({ ObjectControls });

const LogoCameraControls = ({ logoGroup }) => {
  const {
    camera,
    scene,
    gl,
    gl: { domElement },
    size: { width, height },
  } = useThree();

  useEffect(() => {
    const controls = new ObjectControls(camera, domElement, logoGroup.current);
    controls.enableVerticalRotation();
    controls.setDistance(2, 200);
  }, []);

  useFrame((state) => {
    // controls.current.update();
    let aabb = new THREE.Box3().setFromObject(logoGroup.current);
    camera.zoom =
      Math.min(
        width / (aabb.max.x - aabb.min.x),
        height / (aabb.max.y - aabb.min.y)
      ) / 200;
    camera.updateProjectionMatrix();
  });

  return null;
};

const Lights = () => {
  return (
    <React.Fragment>
      <pointLight color={"#c4c4c4"} intensity={1.5} position={[500, 100, 0]} />
      <pointLight color={"#c4c4c4"} intensity={2.5} position={[0, 150, -250]} />
      <pointLight
        color={"#c4c4c4"}
        intensity={0.25}
        position={[-200, -60, 300]}
      />
    </React.Fragment>
  );
};

const SpotLight = () => {
  const [mouseMoved, setMouseMoved] = useState(false);
  const { viewport } = useThree();
  const light = useMemo(() => new THREE.SpotLight(0xffffff), []);

  useFrame(({ mouse }) => {
    if (mouse.x != 0 || mouse.y != 0) {
      setMouseMoved(true);
    }
    if (mouseMoved) {
      const x = (mouse.x * viewport.width) / 2;
      const y = (mouse.y * viewport.height) / 2;
      light.target.position.set(x, y, 0);
      light.target.updateMatrixWorld();
    } else {
      light.target.position.set(-2.5, -2.5, 1);
    }
  });

  return (
    <React.Fragment>
      <primitive
        object={light}
        color={"#ffffff"}
        intensity={2}
        distance={0}
        angle={40 * (Math.PI / 180)}
        penumbra={0.8}
        decay={1}
        position={[0, 0, 5]}
      />
      <primitive object={light.target} position={[-3.9, 0, 1]} />
    </React.Fragment>
  );
};

const Text = () => {
  const font = useLoader(
    THREE.FontLoader,
    "fonts/helvetiker_regular.typeface.json"
  );
  const config = useMemo(
    () => ({ font, size: 12, height: 5, curveSegments: 12 }),
    [font]
  );
  const textGeo = new THREE.TextGeometry("Rcodes", config);
  const ref = useUpdate((geometry) => {
    geometry.fromGeometry(textGeo);
  }, []);

  return (
    <mesh position={[-1.2, -0.5, 0]} scale={[0.1, 0.1, 0.1]}>
      <bufferGeometry attach="geometry" ref={ref} />
      <meshPhongMaterial attach="material" color={"#8d0e05"} shininess={100} />
      <meshPhongMaterial attach="material" color={"#8d0e05"} shininess={100} />
    </mesh>
  );
};

const Ruby = ({ diamond_url }) => {
  const [negativeRubyRotation, setNegativeRubyRotation] = useState(false);
  const { nodes } = useLoader(GLTFLoader, diamond_url);

  const changeRubyRotation = (rotation) => {
    if (!negativeRubyRotation && rotation >= 0.86) {
      setNegativeRubyRotation(true);
    } else if (negativeRubyRotation && rotation <= 0) {
      setNegativeRubyRotation(false);
    }
  };

  const ruby = useRef();
  useFrame(() => (ruby.current.rotation.y += 0.003));

  const { raycaster, scene } = useThree();
  useFrame(() => {
    let intersection = raycaster.intersectObject(ruby.current, true)[0];
    if (intersection) {
      changeRubyRotation(intersection.object.rotation.z);
      let value = negativeRubyRotation ? -0.003 : 0.003;
      intersection.object.rotation.z += value;
    }
  });

  return (
    <mesh
      ref={ruby}
      visible
      geometry={nodes.pCone1_lambert1_0.geometry}
      position={[-3.2, -0.5, 0]}
      scale={[0.2, 0.2, 0.2]}
    >
      <meshPhongMaterial attach="material" color="#8d0e05" shininess={100} />
    </mesh>
  );
};

const DragNotice = () => {
  const notices = document.getElementsByClassName("drag-notice");
  for (let i = 0; i < notices.length; i++) {
    setTimeout(() => {
      notices[i].classList.add("start");
    }, i * 5000);
  }

  return null;
};

const Logo = ({ diamond_url }) => {
  const scene = useRef();
  const logoGroup = useRef();
  const { camera } = useThree();
  useFrame(
    ({ gl }) => void ((gl.autoClear = true), gl.render(scene.current, camera)),
    10
  );

  return (
    <scene ref={scene}>
      <Lights />
      <SpotLight />
      <group ref={logoGroup}>
        <Suspense fallback={<Loading />}>
          <Ruby diamond_url={diamond_url} />
          <LogoCameraControls logoGroup={logoGroup} />
          <Text />
        </Suspense>
      </group>
      <DragNotice />
    </scene>
  );
};

export default Logo;
