import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { animated, useSpring, useTransition } from "react-spring";

const Notice = ({ text, zIndex, delay }) => {
  const transitions = useTransition(true, null, {
    initial: { transform: "scale(0)" },
    from: { transform: "scale(0)" },
    enter: (item) => async (next, cancel) => {
      await new Promise((resolve) => setTimeout(resolve, delay));
      await next({ transform: "scale(1)" });
    },
    leave: { transform: "scale(0)" },
    delay: 10000,
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props }) =>
          item &&
          console.log(props) && (
            <animated.span className="notice" key={key} style={props}>
              {text}
            </animated.span>
          )
      )}
    </div>
  );
};

export default Notice;
