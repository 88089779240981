import React, { Suspense, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Canvas, useFrame, useThree } from "react-three-fiber";
import * as THREE from "three";

import Navbar from "./Navbar";
import SceneManager from "./SceneManager";
import CarConfiguratorControls from "./CarConfiguratorControls";
import About from "./About";
import Contact from "./Contact";

import 'constants/i18n';

const App = ({ diamond_url, smoke_url }) => {
  const [subpage, setSubpage] = useState(null);
  const [subpageDelay, setSubpageDelay] = useState(0);

  const onSubpageChange = (event, value) => {
    event.preventDefault();
    setSubpageDelay(subpage ? 0 : 1000);
    setSubpage(value);
  };

  return (
    <React.Fragment>
      <Navbar onSubpageChange={onSubpageChange} />
      <Canvas
        className={"canvas-container"}
        pixelRatio={Math.min(window.devicePixelRatio, 2)}
        style={{ position: "absolute" }}
      >
        <SceneManager
          diamond_url={diamond_url}
          smoke_url={smoke_url}
          subpage={subpage}
        />
      </Canvas>
      <CarConfiguratorControls subpage={subpage} delay={subpageDelay} />
      <About subpage={subpage} delay={subpageDelay} />
      <Contact subpage={subpage} delay={subpageDelay} />
    </React.Fragment>
  );
};

export default App;
