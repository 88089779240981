import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faInfoCircle,
  faEnvelope,
  faCar,
} from '@fortawesome/free-solid-svg-icons';
import { faGem } from '@fortawesome/free-regular-svg-icons';
import I18n from 'i18n-js';

function Navbar({ onSubpageChange }) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="logo">
          <a
            href="#"
            className="nav-link"
            onClick={(event) => onSubpageChange(event, null)}
          >
            <span className="link-text logo-text">Rcodes</span>
            <FontAwesomeIcon className="fa-9x" icon={faGem} />
          </a>
        </li>

        <li className="nav-item">
          <a
            href="#"
            className="nav-link"
            onClick={(event) => onSubpageChange(event, null)}
          >
            <FontAwesomeIcon className="fa-9x" icon={faHome} />
            <span className="link-text">{ I18n.t('rcodes.navbar.main_page') }</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            href="#"
            className="nav-link"
            onClick={(event) => onSubpageChange(event, 'about')}
          >
            <FontAwesomeIcon className="fa-9x" icon={faInfoCircle} />
            <span className="link-text">{ I18n.t('rcodes.navbar.about') }</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            href="#"
            className="nav-link"
            onClick={(event) => onSubpageChange(event, 'car_configurator')}
          >
            <FontAwesomeIcon className="fa-9x" icon={faCar} />
            <span className="link-text">{ I18n.t('rcodes.navbar.configurator') }</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            href="#"
            className="nav-link"
            onClick={(event) => onSubpageChange(event, 'contact')}
          >
            <FontAwesomeIcon className="fa-9x" icon={faEnvelope} />
            <span className="link-text">{ I18n.t('rcodes.navbar.contact') }</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
