import React from 'react';
import { animated, useTransition } from 'react-spring';
import I18n from 'i18n-js';

function Contact({ subpage, delay }) {
  const transitions = useTransition(subpage === 'contact', null, {
    from: { transform: 'translate3d(0%,-100%, 0)' },
    enter: (item) => async (next, cancel) => {
      await new Promise((resolve) => setTimeout(resolve, delay));
      await next({ transform: 'translate3d(0%, 0%, 0)' });
    },
    leave: { transform: 'translate3d(0%, 400%, 0)' },
  });

  return (
    <div>
      {transitions.map(
        ({ item, key, props }) => item && (
        <animated.div className="content" key={key} style={props}>
          <div className="inner">
            <h2>{ I18n.t('rcodes.contact.header') }</h2>
            <div className="title-divider" />
            <p>email: rut.szymon@gmail.com</p>
          </div>
        </animated.div>
        ),
      )}
    </div>
  );
}

export default Contact;
